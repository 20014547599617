import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Rater from "react-rater";
import { navigate } from "gatsby";
import SEO from "../components/seo";
import AppButton from "../components/common/button";
import { Loading } from "../components/common/loading";
import AppPageHeader from "../components/common/texts/appPageHeader";
import AppLeadingText from "../components/common/texts/appLeadingText";
import { changeAppRatingFormValue, clearAppRatingAction, getAppRatingAction, rateAppAction } from "../state/rateUs.reducer";
import { LINKS } from "../constants/links";
import createRipple from "../components/common/ripple";
import { AppContainer } from "../components/common/appContainer";

const StyledFormWrpaper = styled.div`
	padding: 0 0 50px;
	text-align: center;
	.react-rater {
		color: ${({ theme }) => theme.colors.brandBlue};
		display: flex;
		font-size: 55px;
		justify-content: center;
		width: 100%;
	}
`;
const StyledButtonWrapper = styled.div`
	padding: 15px 0 45px;
	text-align: center;
`;

const RateUsPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { rating, loading, fulfilled } = useSelector((state) => state.rateUs);

	useEffect(() => {
		dispatch(getAppRatingAction());
		return () => {
			dispatch(clearAppRatingAction());
		};
	}, []);

	useEffect(() => {
		if (fulfilled) {
			navigate(LINKS.RATE_US_SEND);
		}
	}, [fulfilled]);

	const handleChange = (value) => {
		dispatch(changeAppRatingFormValue(value));
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		dispatch(rateAppAction(rating));
	};

	return (
		<>
			<SEO title={t("rateUs.title")} />
			<AppContainer>
				<form autoComplete="off" onSubmit={handleSubmit}>
					<AppPageHeader>{t("rateUs.header")}</AppPageHeader>
					<AppLeadingText>{t("rateUs.description")}</AppLeadingText>
					<StyledFormWrpaper>
						<Rater onClick={createRipple} onRate={({ rating }) => handleChange(rating)} rating={rating} total={5} />
					</StyledFormWrpaper>
					<StyledButtonWrapper>
						<AppButton disabled={!rating}>{t("contactUs.sendButton")}</AppButton>
					</StyledButtonWrapper>
				</form>
				{loading && <Loading text={t("rateUs.loadingText")} />}
			</AppContainer>
		</>
	);
};

export default RateUsPage;
